import React from "react"
import { graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Section from "../../components/Section";
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline";
import VimeoEmbed from "../../components/VimeoEmbed";

export default class ChooseLife extends React.Component {
  render() {
    return ( 
      <Layout>
        <Seo title="Choose Life" />
        <Section className="white">
          <Container >
            <Row className="py-5 align-items-center">
              <Col xs={12} md={6} className="pb-5 pb-md-0">
                <StaticImage placeholder="none" src="../../assets/img/events/choose-life/choose-life-logo.webp" alt="Choose Life" style={{maxWidth: "400px", margin: "auto", width: "100%"}} ></StaticImage>
              </Col>
              <Col xs={12} md={6}>
                <Headline className="tertiary">What is Choose Life?</Headline>
                <p>Let's face it - life can be hard.  Really hard.  Sometimes it feels like we just can't face another day.  
                We've been there.  Worry.  Loss.  Hurt.  Bad things you've done.  Awful things done to you.  It can trigger 
                depression and dark thoughts, even thinking of ending it all.  We're here to tell you that even when things 
                seem hopeless, you can still find hope.  Join in with Native young people from across North America as we 
                look at what hope looks like.  You will quickly see that you are not alone, that others have walked the dark 
                road you are on, and that they have found hope and freedom.  And you can, too - you are not alone!  So tune in to 
                what the Creator God has for you, and learn how you can Choose Life!</p>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="tertiary">
          <Container >
            <Row className="py-5 align-items-center">
              <Col xs={12} md={6}>
                <Headline className="tertiary">What is Choose Life?</Headline>
                <p>The live broadcast has ended. This rebroadcast does not include the live concert 
                from the guest artist, and the giveaway items have already been awarded to the live audience winners.</p>
              </Col>        
              <Col xs={12} md={6}>
                <VimeoEmbed vimeoId="460696004" title="Choose Life" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="chooseLifeHopeSection secondary">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="tertiary" center={true}>Choosing Life</Headline>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={2} sm={3} md={4} lg={4}>
              </Col>
              <Col xs={9} sm={9} md={8} lg={7}>
                <Container>
                  <Row className="dot-left mt-3 pl-4 pb-4">
                    <Col className="font-weight-bold">
                      If you prayed tonight, please text the word <span className="tertiary">HOPE</span> to <span className="tertiary">205-857-4890</span>, or click <Link to="/hope">HOPE</Link> now.
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4">
                    <Col className="font-weight-bold">
                      If you need to talk to someone right now about struggles you're having with suicidal thoughts, 
                      please call the <span className="tertiary">National Suicide Prevention Lifeline</span> at <a href="tel:18002738255">800-273-8255</a>.
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="chooseLifeSpeakersSection dark">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="tertiary" center={true}>Speakers</Headline>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={2} sm={3} md={4} lg={4}>
              </Col>
              <Col xs={9} sm={9} md={8} lg={7}>
                <Container>
                  <Row className="dot-left mt-3 pl-4 pb-5">
                    <Col>
                      <h5>Nick Liew</h5>
                      <div><em>Jicarilla Apache / Chinese</em></div>
                      <div>On Eagles' Wings Coordinator; Oklahoma State alum; Married for 3 ½ years + one beautiful little girl; Texas pro sports fan</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Weston Francis</h5>
                      <div><em>Navajo</em></div>
                      <div>On Eagles' Wings staff member; Indian Bible College alum; Yankees fan; loves cheesecake</div>
                    </Col>
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Lani Liew</h5>
                      <div><em>Tonawanda Seneca/Hopi</em></div>
                      <div>She moved from upstate New York to work for On Eagles' Wings full-time. She brought equal parts sparkle and New York attitude to the office with her.</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Tyonna</h5>
                      <div><em>Navajo</em></div>
                      <div>Ty grew up facing a lot of hurt and loneliness, but she found hope and life!  And now she's shared that hope with thousands of people around the country as part of the On Eagles' Wings team.</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Brad Hutchcraft</h5>
                      <div>On Eagles' Wings Director; worked with youth on Hopi reservation for 14 years; Married for 17 years + three amazing children; Yankees fan</div>
                    </Col>              
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col xs={4} md={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker1)} alt="Nick Liew" />
              </Col>
              <Col xs={4} md={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker2)} alt="Weston Francis" />
              </Col>
              <Col xs={4} md={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker3)} alt="Lani Liew" />
              </Col>
              <Col xs={4} md={2} className="px-1 pt-2 pt-md-0">
                <GatsbyImage image={getImage(this.props.data.speaker4)} alt="Tyonna" />
              </Col>
              <Col xs={4} md={2} className="px-1 pt-2 pt-md-0">
                <GatsbyImage image={getImage(this.props.data.speaker5)} alt="Brad Hutchcraft" />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={2} sm={3} md={4} lg={4}>
              </Col>
              <Col xs={9} sm={9} md={8} lg={7}>
                <Container>
                  <Row className="dot-bottom mt-4 pl-4 pb-5">
                    <Col>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="secondary chooseLifeArtistSection">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={10}>
                <Headline className="tertiary">Artists</Headline>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={6} sm={6} md={5} lg={4}>
                <h5>Lacey Sturm</h5>
                <p>
                  Lacey Sturm has earned legions of fans worldwide not only through her astoundingly emotive voice and chart 
                  topping songwriting, but also for the sheer sincerity of the uplifting messages pervading her work. Whether 
                  fronting multi-million-selling hard rockers Flyleaf or now as a solo artist (not to mention as an author and speaker), 
                  she remains uncommonly true to herself while inspiring others to strive for better ways to live and love.
                </p>
              </Col>
              <Col xs={6} sm={6} md={5} lg={6}>
                <Container>
                  <Row className="dot-left mt-3 pl-4 mb-0 pb-0">
                    <Col>
                      <GatsbyImage className="mt-3" image={getImage(this.props.data.artist1)} alt="Lacey Sturm" />
                    </Col>
                  </Row>
                  <Row className="dot-bottom mt-0 pt-1">
                    <Col>
                      &nbsp;
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    speaker1: file(relativePath: { eq: "events/choose-life/Nick-Liew.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker2: file(relativePath: { eq: "events/choose-life/Weston-Francis.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker3: file(relativePath: { eq: "events/choose-life/Lani-Liew.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    speaker4: file(relativePath: { eq: "events/choose-life/Tyonna.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }        
    speaker5: file(relativePath: { eq: "events/choose-life/Brad-Hutchcraft.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    artist1: file(relativePath: { eq: "events/choose-life/Lacey-Sturm.webp" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 527 
          height: 327
          )
      }
    }
  }
`
